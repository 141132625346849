import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemIcon, ListItemText } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import OpenLayerMap from './OpenLayerMap.js';

export default function Contact() {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={6} xl={6}>
                    <OpenLayerMap />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <Typography variant="h4" color="text.primary" paragraph sx={{
                        marginBottom: 0,
                        textAlign: {
                            xs: 'center',
                            sm: 'center',
                            md: 'left',
                            lg: 'left',
                            xl: 'left',
                        },
                    }}>
                        Kontakty
                    </Typography>
                    <List sx={{ width: '100%', bgcolor: 'background.paper', paddingLeft: 2 }}>
                        <ListItem divider>
                            <ListItemIcon>
                                <CallIcon sx={{ fontSize: 35 }} />
                            </ListItemIcon>
                            <ListItemText sx={{ paddingLeft: 2 }}>
                                <a style={{ textDecoration: "none", color: "#285c97" }} href="tel://+421 908 795 375" >+421 908 795 375</a>
                            </ListItemText>
                        </ListItem>

                        <ListItem divider>
                            <ListItemIcon>
                                <EmailIcon sx={{ fontSize: 35 }} />
                            </ListItemIcon>
                            <ListItemText sx={{ paddingLeft: 2 }}>
                                <a style={{ textDecoration: "none", color: "#285c97" }} href="mailto://stefanko@vyrobapruzin.sk" >stefanko@vyrobapruzin.sk</a>
                            </ListItemText>
                        </ListItem>

                        <ListItem divider>
                            <ListItemIcon>
                                <LanguageIcon sx={{ fontSize: 35 }} />
                            </ListItemIcon>
                            <ListItemText sx={{ paddingLeft: 2 }}>
                                <a style={{ textDecoration: "none", color: "#285c97" }} href="https://vyrobapruzin.sk/" >https://vyrobapruzin.sk/</a>
                            </ListItemText>
                        </ListItem>
                    </List>
                    <Typography variant="h4" color="text.primary" paragraph sx={{
                        marginBottom: 0,
                        textAlign: {
                            xs: 'center',
                            sm: 'center',
                            md: 'left',
                            lg: 'left',
                            xl: 'left',
                        },
                    }}>
                        Prevádzková doba
                    </Typography>
                    <List sx={{ width: '100%', bgcolor: 'background.paper', paddingLeft: 2 }}>
                        <ListItem divider>
                            <ListItemIcon>
                                <AccessTimeIcon sx={{ fontSize: 35 }} />
                            </ListItemIcon>
                            <ListItemText sx={{ paddingLeft: 2 }}>
                                Po-Pia: 7:00h - 16:00h
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Typography variant="h4" align="left" color="text.primary" paragraph sx={{
                        marginBottom: 0,
                        textAlign: {
                            xs: 'center',
                            sm: 'center',
                            md: 'left',
                            lg: 'left',
                            xl: 'left',
                        },
                    }}>
                        Fakturačné údaje
                    </Typography>
                    <List sx={{ width: '100%', bgcolor: 'background.paper', paddingLeft: 2 }}>
                        <ListItem>
                            <ListItemIcon>
                                <HomeIcon sx={{ fontSize: 40 }} />
                            </ListItemIcon>
                            <ListItemText sx={{ paddingLeft: 3, fontSize: 100 }}>
                                Ján Štefanko - výroba pružín <br />
                                Upohlav 604, 018 01 - Udiča
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIndIcon sx={{ fontSize: 40 }} />
                            </ListItemIcon>
                            <ListItemText sx={{ paddingLeft: 3, fontSize: 100 }}>
                                IČO: 43078303 <br />
                                DIČ: 1070492258
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}